class ClickTrackerElement extends HTMLElement {
	connectedCallback() {
		if (!window.mixpanel) return;
		this.addEventListener('click', this.sendEvent.bind(this));
	}

	getEventProps(): Record<string, string | null> {
		const props: Record<string, string | null> = {};

		this.getAttributeNames().forEach((trackAttr) => {
			if (!trackAttr.includes('data-track-')) return;
			const propName = trackAttr.split('data-track-')[1];
			props[propName] = this.getAttribute(trackAttr);
		});

		return props;
	}

	sendEvent() {
		if (this.dataset.eventName && window.mixpanel) {
			const props = this.getEventProps();
			window.mixpanel.track(this.dataset.eventName, props);
		}
	}
}

customElements.define('click-tracker', ClickTrackerElement);
